interface UserReview {
  author: string;
  comment: string;
}

export const userReviews: UserReview[] = [
  {
    author: 'Julliet',
    comment:
      'I lost a lot of weight without giving up on any of my favorite foods! I am shocked to see results so fast.',
  },
  {
    author: 'Walter',
    comment: `Fantastic pills, using them for 2 weeks now and already seeing results. Thanks Nova!`,
  },
  {
    author: 'Vanessa M.',
    comment: `I have been using this product for a few weeks and have dropped 15 pounds already.`,
  },
  {
    author: 'Victoria T.',
    comment:
      'It’s unbelievable... My skin has not been so clear since my graduation! Fantastic!',
  },
  {
    author: 'JohnPork',
    comment:
      'Solid product, the pills are easy to swallow and the effects appeared quick.',
  },
  {
    author: 'S Pamela.',
    comment:
      'I got rid of the extra weight, and my memory is much better. It truly exceded my expectations.',
  },
];
