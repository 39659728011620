interface SuccessStory {
  imgUrl: string;
  story: string;
  author: string;
}

export const SuccessStories: SuccessStory[] = [
  {
    imgUrl:
      'https://res.cloudinary.com/drfweekiv/image/upload/v1722235423/Frame_1214134128_11.png',
    story: `"I’ve been insecure about my belly fat since my teen years and tried everything. My best friend recommended Nova, and wow... For the first time in 10 years, I can wear my bikini with confidence. Seriously, unbelievable."`,
    author: 'Lilly L., 30',
  },
  {
    imgUrl:
      'https://res.cloudinary.com/drfweekiv/image/upload/v1722235423/Frame_1214134128_12.png',
    story: `"I started using Nova supplements a few months ago, and I've noticed a huge improvement not only in my hair and skin, but also in my immune system. I feel healthier and more resilient to seasonal illnesses, like colds and flu."`,
    author: 'Vivienne Q., 35',
  },
  {
    imgUrl:
      'https://res.cloudinary.com/drfweekiv/image/upload/v1722235423/Frame_1214134128_13.png',
    story: `"5 months in, I lost 20 lbs and my bloating completely went away! Now I feel so much more confident and I keep getting compliments on my body shape. Overall, I feel amazing. Great investment in my health!"`,
    author: 'Jesse M, 29',
  },
  {
    imgUrl:
      'https://res.cloudinary.com/drfweekiv/image/upload/v1722235423/Frame_1214134128_14.png',
    story: `"I see a significant change in my skin. My acne has cleared up, and it’s much more radiant. Plus, my digestive discomfort and late night cravings have drastically reduced. Nova has truly improved my daily life."`,
    author: 'Whitney M., 28',
  },
  {
    imgUrl:
      'https://res.cloudinary.com/drfweekiv/image/upload/v1722235423/Frame_1214134128_15.png',
    story:
      '"I feel more energized and have noticed improvements in my sleep. These changes make a real difference and even reflect in my results at work. I recommend Nova supplements to anyone looking to improve their performance."',
    author: 'Jacob, 33',
  },
  {
    imgUrl:
      'https://res.cloudinary.com/drfweekiv/image/upload/v1722235424/Frame_1214134128_16.png',
    story:
      '"Since I started noticing my wrinkles, I invested so much money into my skincare. I really wish I had discovered Nova earlier. I myself can’t believe I am already 36. Never expected a supplement would work this well."',
    author: 'Jennifer, 36',
  },
];
