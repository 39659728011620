import React, { FC } from 'react';

interface TrustpilotStarsPartialProps {
  starCount: number;
}

const TrustpilotStarsPartial: FC<TrustpilotStarsPartialProps> = ({
  starCount,
}) => (
  <>
    {Array.from({ length: starCount }).map((_, index) => (
      <svg
        key={index}
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M20 0.131592H0.263184V19.8684H20V0.131592Z" fill="#DADADA" />
        <path d="M20 0.131592H0.263184V19.8684H20V0.131592Z" fill="#DADADA" />
        <path
          d="M16.9976 0.000244141H-0.00244141V20.0002H16.9976V0.000244141Z"
          fill="#DADADA"
        />
        <path
          d="M16.9976 0.000244141H-0.00244141V20.0002H16.9976V0.000244141Z"
          fill="#00A676"
        />
        <path
          d="M16.9976 7.99662H11.6414L9.99756 3.00037L8.35373 7.99662H2.99756L7.37868 11.0708L5.67243 16.067L9.99756 12.9707L14.3227 16.067L12.6132 11.0708L16.9976 7.99662Z"
          fill="#FAFDFA"
        />
      </svg>
    ))}
  </>
);

export default TrustpilotStarsPartial;
