import React, { FC } from 'react';

interface TrustpilotStarsProps {
  starCount: number;
}

const TrustpilotStars: FC<TrustpilotStarsProps> = ({ starCount }) => (
  <>
    {Array.from({ length: starCount }).map((_, index) => (
      <svg
        key={index}
        width="21"
        height="20"
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M20.6841 0.131592H0.947266V19.8684H20.6841V0.131592Z"
          fill="#00A676"
        />
        <path
          d="M18.002 7.99662H12.6458L11.002 3.00037L9.35812 7.99662H4.00195L8.38308 11.0708L6.67683 16.067L11.002 12.9707L15.3271 16.067L13.6176 11.0708L18.002 7.99662Z"
          fill="#FAFDFA"
        />
      </svg>
    ))}
  </>
);

export default TrustpilotStars;
