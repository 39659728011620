import React, { FC } from 'react';

const VerifiedIcon: FC = () => (
  <svg
    width="11"
    height="11"
    viewBox="0 0 11 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Isolation_Mode" clipPath="url(#clip0_92_530)">
      <path
        id="Vector"
        d="M5.5672 0.0450928L5.50074 0L5.43428 0.0450928C5.43428 0.0450928 2.03301 1.78117 0.5 2.43899C0.5 2.43899 1.23401 6.15119 1.76717 7.82626C2.30032 9.50265 4.36649 10.4005 5.50074 10.9987C6.63351 10.4005 8.70115 9.50265 9.23431 7.82626C9.76746 6.14987 10.5015 2.43899 10.5015 2.43899C8.96699 1.78117 5.5672 0.0450928 5.5672 0.0450928Z"
        fill="white"
      />
      <path
        id="Vector_2"
        d="M5.15084 7.22149L2.89563 5.48541C2.67853 5.31831 2.65342 5.0252 2.83951 4.83157C3.0256 4.63661 3.35199 4.61406 3.56762 4.78117L5.04598 5.9191L7.57589 3.33024C7.76345 3.13793 8.08984 3.11804 8.30547 3.2878C8.51962 3.45624 8.54177 3.75067 8.35273 3.94297L5.14936 7.22149H5.15084Z"
        fill="#2FBE8F"
      />
    </g>
    <defs>
      <clipPath id="clip0_92_530">
        <rect width="10" height="11" fill="white" transform="translate(0.5)" />
      </clipPath>
    </defs>
  </svg>
);

export default VerifiedIcon;
