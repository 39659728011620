interface VideoReview {
  url: string;
  title: string;
  description: string;
  name: string;
}

export const videoReviews: VideoReview[] = [
  {
    url: 'https://res.cloudinary.com/drfweekiv/video/upload/v1722236170/nova-1.mp4',
    title: 'Curbed my sweet tooth',
    description: `"I used to get insane sugar cravings, especially after meals. Now I feel much less tempted to snack. Nova has helped me lose a few pounds and maintain it."`,
    name: 'Sarah K.',
  },
  {
    url: 'https://res.cloudinary.com/drfweekiv/video/upload/v1722236170/nova-2.mp4',
    title: 'Reduced my cravings',
    description: `"Nova has reduced my cravings for greasy snacks. I feel more in control of my diet, especially in the evenings when my cravings would usually kick in."`,
    name: 'Melissa P.',
  },
  {
    url: 'https://res.cloudinary.com/drfweekiv/video/upload/v1722236170/nova-3.mp4',
    title: 'My hair and skin improved',
    description: `"I used to struggle with breakouts and dry, brittle hair, but since starting Nova supplements, my complexion is radiant, and my hair feels stronger and shinier."`,
    name: 'Victoria S.',
  },
  {
    url: 'https://res.cloudinary.com/drfweekiv/video/upload/v1722236170/nova-4.mp4',
    title: 'Amazing experience',
    description: `"Nova supplements have made a huuuge difference in my daily energy levels. I feel more alert and less fatigued throughout the day, even during my busiest hours."`,
    name: 'Jenny F.',
  },
  {
    url: 'https://res.cloudinary.com/drfweekiv/video/upload/v1722236170/nova-5.mp4',
    title: 'Improved my mental health',
    description:
      '"I feel more balanced and less stressed, especially during high pressure situations at work. Nova has helped me manage stress better and stay positive."',
    name: 'Chloe W.',
  },
];
